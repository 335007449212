
body {
  background: #E6E6E6;
}

img {
  width: 220px;
  margin-top: 40px;
  margin-left: 40px;
}
div #login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
