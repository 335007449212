button {
  margin-top: 30;
  margin-bottom: 30;
}

.tempobaixo {
  color: #008000;
  text-align: center;
}
.tempomedio {
  font-family: serif;
  color: #ffc107;
  text-align: center;
}

.tempocritico {
  font-family: serif;
  color: #b80000;
  text-align: center;
  border-style: solid;
  border-color: red;
  border-radius: 10px;
  animation: animate 1.5s linear infinite;
}
.subtitle {
  font-weight: 1200;
  font-size: 12px;
  color: #b80000;
}
.textoarea {
  font-family: serif;
  font-weight: 400;
  color: #050505;
}
.textoareanfrota {
  font-family: serif;
  font-weight: 700;
  color: #1a237e;
}
.textoarepallet {
  font-family: serif;
  font-weight: 700;
  color: #1a237e;
}
.textoareaplaca {
  font-family: serif;
  font-weight: 700;
  color: #050505;
  border-width: 2px;
  border-top: #1a237e;
  border-top-width: 7px;
  border-style: solid;
  border-radius: 7px;
  justify-items: center;
  align-items: center;
  width: 80px;
  height: 32px;
  background-color: #cccccc;
}
.textoareaplacadefinir {
  font-family: serif;
  font-weight: 700;
  color: #050505;
  border-width: 2px;
  border-top: #b71c1c;
  border-top-width: 7px;
  border-style: solid;
  border-radius: 7px;
  justify-items: center;
  align-items: center;
  width: 80px;
  background-color: #cccccc;
}
.textoarealoja {
  font-family: serif;
  font-weight: 400;
  height: 25px;
  width: 25px;
  color: #ffff;
  text-align: center;
  border-style: solid;
  border-color: #1f2880;
  border-radius: 40px;
  background-color: #1a237e;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
}
.textoarealojaentregue {
  font-family: serif;
  font-weight: 400;
  height: 25px;
  width: 25px;
  color: #ffff;
  text-align: center;
  border-style: solid;
  border-color: #2d8659;
  border-radius: 40px;
  background-color: #2d8659;
  align-items: center;
  justify-items: center;
  justify-content: center;
  text-align: center;
}

.textoareaplacatruck {
  font-family: serif;
  font-weight: 700;
  color: #050505;
  border-width: 2px;
  border-top: #1a237e;
  border-top-width: 7px;
  border-style: solid;
  border-radius: 7px;
  justify-items: center;
  align-items: center;
  width: 80px;

  background-color: #cccccc;
}

.qtdveiculoAdefinir {
  font-family: serif;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  animation: animate 1.5s linear infinite;
}
.containersatelite {
  display: flex;
  justify-content: center;
  position: relative;
  animation: move 3s linear infinite; /* Animação de movimento */
}

.satelite {
  font-family: serif;
  color: #b80000;
  text-align: center;
  border-radius: 10px;
  animation: blink 1.5s linear infinite; /* Animação de piscar */
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@keyframes move {
  0% {
    transform: translateX(0); /* Posição inicial */
  }
  50% {
    transform: translateX(100px); /* Mover para a direita */
  }
  100% {
    transform: translateX(0); /* Voltar para a posição inicial */
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
