.map {
    height: 100vh;
  }
  
  .map-marker {
    margin-top: -30px;
  }
  
  .address {
    box-sizing: border-box;
    width: 270px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    position: absolute;
    left: 10px;    
    margin-top: 62px;
  }
  
  .addressField {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }
  .btnremove{
    box-sizing: border-box;
    border: 1px solid transparent;   
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    margin-left: 1px;
  }
  .btnadd{
    box-sizing: border-box;
    border: 1px solid transparent;  
    width: 45px; 
    height: 32px;
    margin-bottom: 8px;
    
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    margin-left: 1px;
    display: block;
    margin-left: auto;
    margin-right: 14px;
   
    
  }
  .tracarrotar{
   
    box-sizing: border-box;
    border: 1px solid transparent;   
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    color: rgb(78, 78, 83);
    outline: none;
    text-overflow: ellipses;
    margin-left: 1px;
    
  }
  .tracarrotar:hover{
    color: white;
    background-color: #2d8659 
  }


  .cadastrarrota{
   
    box-sizing: border-box;
    border: 1px solid transparent;   
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    color: rgb(78, 78, 83);
    outline: none;
    text-overflow: ellipses;
    margin-top: 10px;
    margin-left: 18px;
    
  }
  .cadastrarrota:hover{
    color: white;
    background-color: #d27979 
  }

  .btnremove:hover{
    background-color: #B3E5CC;
  }

  .btnadd:hover{
    background-color: #B3E5CC;
  }